import { Component, input, numberAttribute } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/components/icon';
import { TColors } from '@shared/interfaces/root-type.interface';

@Component({
    selector: 'custom-spinner',
    imports: [TranslateModule, IconComponent],
    template: `
        <icon
            [color]="color()"
            [customColor]="customColor()"
            [size]="size()"
            name="spinner"
            class="mb-item-sm" />
        <div>{{ text() | translate }}...</div>
    `,
    styles: `
        :host {
            display: flex;
            flex-direction: column;
            align-items: center;
            place-content: center;
        }
    `,
})
export class CustomSpinnerComponent {
    color = input<TColors>('primary');
    customColor = input<string>('');
    size = input<number | undefined, unknown>(28, { transform: numberAttribute });
    text = input<string>('');
}
